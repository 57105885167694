<template>
  <div class="projects" id="projects">
      <div class="mobile-title">
          <p>פרויקטים</p>
      </div>
      <div class="sort">
          <div class="flex-row">
            <i class="el-icon-info" style="margin-left:10px; margin-right: 5px;"></i>
            <p>פרוייקטים רגישים / בעלי יתרון תחרותי לא יוצגו כאן.</p>
          </div>
          <div class="flex-row">
            <i class="el-icon-info" style="margin-left:10px; margin-right: 5px;"></i>
            <p>כפילויות ופרוייקטים דומים לא יוצגו גם כן.</p>
          </div>
      </div>
      <div class="apps-grid">
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/roslog-web.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>אתר קידום ומכירות</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/dutypharm.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>עמדת השוואת מחירים</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/office-req.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>מערכת ניהול רשת ועובדים</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/agent-tablet.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>אלבום ומערכת סוכני מכירות</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/comax.png" alt="">
              </div>
              <div class="title slide-in-top">
                  <p>אינטגרציית מערכות קומקס</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/wps-net.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>מערכת מחסן חכם</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/priority.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>אינטגרציית מערכות פריורטי</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/review-app.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>מערכת לביצוע ביקורות</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/agent-returns.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>מערכת ניהול ומעקב החזרות</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/dumdum.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>אתר נחיתה אינטרקטיבי</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
          <div class="app-box">
              <div class="image-slider">
                  <img src="@/assets/college.png" alt="">
              </div>
              <div class="title slide-in-top" >
                  <p>הזמנת מקום ורכישת כרטיסים</p>
              </div>
              <div class="more-info slide-in-bottom">
                    <el-button type="success" style="width:75%;">למד עוד</el-button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';

export default {
name: 'Projects',
components: {},
setup(){
    onMounted(() => {
        const projects = document.getElementById('projects');
        if(projects){
            projects.classList.toggle('fade-in')
        }
    })
    
    return{

    }
}
}
</script>
<style scoped>
.projects{
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "sort"
    "apps-grid ";
    grid-template-columns: 1fr;
    grid-template-rows: 5% 93%;
    padding: 65px;
    justify-content: center;
}
.sort{
    grid-area: sort;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    color: whitesmoke;
}
.apps-grid{
    margin-top: 10px ;
    grid-area: apps-grid;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: calc(76vw / 4) calc(76vw / 4) calc(76vw / 4) calc(76vw / 4);
    grid-auto-rows: calc(76vw / 4);
    gap: 1vw;
    overflow-y: auto;
    justify-content: center;
}
.app-box{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.app-box .more-info{
    position: absolute;
    bottom: 25px;
    width: 100%;
    height: 15%;
    display: none;
    align-items: center;
    justify-content: center;
}
.app-box .title{
    position: absolute;
    top: 25px;
    width: 100%;
    height: 15%;
    background-color: var(--bg-green);
    display: none;
    align-items: center;
    justify-content: center;
    color: white;
}
.app-box .title p{
    width: 100%;
    text-align: center;
    font-size: 24px;
}
.app-box:hover .title{
    display: flex;
}
.app-box:hover .more-info{
    display: flex;
}
.image-slider{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.image-slider img{
    width: 100%;
    max-height: auto;
}
.flex-row{
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.mobile-title{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100%);
    height: 70px;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    display: none;
    z-index: 1;
    font-size: 4vh;
}
@media only screen and (max-width: 1004px) {
  .mobile-title{
    display: flex;
   }
  .projects{
    height: 100%;
    grid-template-rows: 0.05fr 0.95fr;
    width: 100%;
    padding: 70px 0 0 0;
    grid-template-rows: 5% 95%;
  }
  .sort{
    border-radius: 0;
  }
  .apps-grid{
    grid-template-columns: 1fr;
    grid-auto-rows: 55%;
    gap: 10px;
    padding: 10px;
    height: calc(100% - 20px);
  }
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
